import React from "react"
import Header from "../components/header"
import Footer from "../components/footer"
import Directory from "../components/directory"
import SeO from "../components/SEO"
export default function directory() {
  return (
    <div>
      <SeO title={`PrintDirectory`} defer={true} />
      <Header />
      <Directory />
      <Footer />
    </div>
  )
}
